import { blogCall } from ".";

class BlogsApi {
  addBlogs(params, data) {
    // console.log("hii calling from addblog function");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await blogCall(
            "post",
            "upload-dealer-blogs",
            params,
            data
          );
          resolve(res?.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })();
    });
  }

  getDealerBlogs(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await blogCall("get", "dealer-get-upload-blogs", params);
          resolve(res?.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })();
    });
  }

  // getDealerBlog(id) {
  //     return new Promise((resolve, reject) => {
  //       (async () => {
  //         try {
  //           const res = await getBlogs("get", "dealer-get-upload-blogs", { id });
  //           resolve(res?.data);
  //         } catch (error) {
  //           console.log(error.response?.data || error.message);
  //           reject(error);
  //         }
  //       })();
  //     });
  //   }

  async setStatus(data) {
    try {
      const res = await blogCall("put", "set-status", null, data);
      return res?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export const blogs = new BlogsApi();
