import { EDIT_SELLER_LEAD, GET_SELLER_LEAD } from "../types";
import { sellerApi } from "../../api/sellerLeadApi";

export const getSellerList = (params) => (dispatch, getState) => {
  const { sellerLeadList } = getState().sellerLead;
  return sellerApi.getSellerLead(params).then(
    (response) => {
      dispatch({
        type: GET_SELLER_LEAD,
        payload: {
          data:
            sellerLeadList && params?.page > 1
              ? [...sellerLeadList, ...response?.data]
              : response?.data,
          total: response?.total,
        },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const editSellerDetails = (payload) => (dispatch) => {
  return sellerApi.editSellerLead(payload).then(
    (data) => {
      dispatch({
        type: EDIT_SELLER_LEAD,
        payload: payload,
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
