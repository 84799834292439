import { EDIT_BUYER_LEAD, GET_BUYER_LEAD } from "../types";

const initialState = {
  buyerLeadList: [],
  total: 10,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EDIT_BUYER_LEAD:
      const updateBuyerLead = state.buyerLeadList.map((buyerLead) => {
        if (buyerLead.lead_id === payload.lead_id) {
          return { ...buyerLead, ...payload };
        }
        return buyerLead;
      });
      return {
        ...state,
        buyerLeadList: updateBuyerLead,
      };

    case GET_BUYER_LEAD:
      return {
        ...state,
        buyerLeadList: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
