import React, { useEffect, useState } from "react";
import style from "./editCarDetails.module.scss";
import ImageCard from "../add-new-car/components/image-card/ImageCard";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import SelectableButton from "../../components/buttons/SelectableButton";
import InputField from "../../components/input-field/InputField";
import BackButton from "../../components/buttons/BackButton";
import { postData } from "../../components/services/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { publicApi } from "../../api/publicApi";
import { editCarrDetails } from "../../store/car/carAction";
import { carApi } from "../../api/carApi";
import MultiSelect from "../../components/buttons/multiSelect";
import DateField from "../../components/input-field/dateField";
import moment from "moment";
import { toast } from "react-toastify";

export default function EditCarDetails() {
  const [loaderTrue, setloaderTrue] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [varientList, setVarientList] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [alreadyImg, setalreadyImg] = useState([]);
  const [flags, setFlags] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [imageTypeEnum, setimageTypeEnum] = useState([]);

  const brands = async () => {
    try {
      const res = await publicApi.brandList();
      setBrandList(res?.data);
      const res1 = await carApi.imgTypeEnumList();
      setimageTypeEnum(res1);
    } catch (err) {
      console.log(err);
    }
  };

  const getCarDetailById = async (data) => {
    try {
      setloaderTrue(true);
      const res = await carApi.getVehicleInfo(data);
      setloaderTrue(false);
      setalreadyImg(res["data"] || []);
    } catch (err) {
      console.log(err);
      setloaderTrue(false);
    }
  };
  const [formData, setFormData] = useState({
    vehicleNo: state?.data?.vehicle_reg_no || "",
    brand_id: state?.data?.brand_id || "",
    fuel_type: state?.data?.fuel_type || "",
    status: state?.data?.status || "",
    transmission_id: state?.data?.transmission_id || "",
    images: state?.data?.images || null,
    make_year: state?.data?.make_year || null,
    actual_price: state?.data?.actual_price || "",
    owner: state?.data?.owner || null,
    driven_kms: state?.data?.driven_kms || "",
    discounted_price: state?.data?.discounted_price || "",
    discountPercent: state?.data?.discountPercent || "",
    model_id: state?.data?.model_id || "",
    variant_id: state?.data?.variant_id || "",
    car_id: state?.data?.car_id,
    insurance_type_id: state?.data.insurance_type_id || "",
    insur_validity_dt: state?.data.insur_validity_dt || null,
  });

  useEffect(() => {
    const images = {};
    imagesData.forEach((item) => {
      images[item.apikey] = new FormData(item.file);
    });

    setFormData({
      vehicleNo: state?.data?.vehicle_reg_no || "",
      brand_id: state?.data?.brand_id || "",
      fuel_type: state?.data?.fuel_type || "",
      status: state?.data?.status || "",
      transmission_id: state?.data?.transmission_id || "",
      images: state?.data?.images || null,
      make_year: state?.data?.make_year || null,
      actual_price: state?.data?.actual_price || "",
      owner: state?.data?.owner || null,
      driven_kms: state?.data?.driven_kms || "",
      discounted_price: state?.data?.discounted_price || "",
      discountPercent: state?.data?.discountPercent || "",
      model_id: state?.data?.model_id || "",
      variant_id: state?.data?.variant_id || "",
      car_id: state?.data?.car_id,
      insur_validity_dt: state?.data.insur_validity_dt
        ? moment(state?.data.insur_validity_dt).format("YYYY-MM-DD")
        : null,
      insurance_type_id: state.data.insurance_type_id || null,
    });

    if (state && state.data) {
      const initialFlags = [];
      if (state?.data?.trending === 1) {
        initialFlags.push({ label: "TRENDING", value: "TRENDING" });
      }
      if (state?.data?.discounted === 1) {
        initialFlags.push({ label: "DISCOUNTED", value: "DISCOUNTED" });
      }
      if (state?.data?.new_stock === 1) {
        initialFlags.push({ label: "NEWSTOCK", value: "NEWSTOCK" });
      }
      if (state?.data?.new_model === 1) {
        initialFlags.push({ label: "NEWMODEL", value: "NEWMODEL" });
      }
      setFlags(initialFlags);
    }
  }, [state]);

  const models = async () => {
    try {
      const res = await publicApi.modelList({ brandId: formData?.brand_id });
      setModelList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const varients = async () => {
    try {
      const res = await publicApi.varientList({ modelId: formData?.model_id });
      setVarientList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    brands();
    getCarDetailById(state?.data?.car_id);
  }, [state?.data?.car_id]);

  useEffect(() => {
    if (formData?.brand_id) models();
  }, [formData?.brand_id]);

  useEffect(() => {
    if (formData?.model_id) varients();
  }, [formData?.model_id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();
  const editCar = (e) => {
    setLoading(true);
    setloaderTrue(true);
    e.preventDefault();

    const images = {};
    const flagValue = [];
    flags.forEach((item) => {
      flagValue.push(item.value);
    });

    imagesData.forEach((item) => {
      images[item.apikey] = item.file;
    });

    dispatch(
      editCarrDetails({
        ...formData,
        actual_price:
          (formData?.discounted_price * formData?.discountPercent) / 100 +
          Number(formData?.discounted_price),
        ...images,
        flags: flagValue,
      })
    )
      .then((res) => {
        setLoading(false);
        setloaderTrue(false);
        navigate("/car-management");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.msg || "Internal Server Error");
        setLoading(false);
        setloaderTrue(false);
      });
  };

  // console.log("state", state);
  // console.log("formdata", formData);

  return (
    <div className={style["container"]}>
      {loaderTrue ? (
        <div class="loading">
          <div class="loader"></div>
        </div>
      ) : null}
      <div className={style["header-wrapper"]}>
        <BackButton
          onClick={() => {
            navigate("/car-management");
          }}
        />
        <PrimaryHeading
          title={"Edit Car Details"}
          fontSize={"1.75rem"}
          fontWeight={600}
        />
      </div>

      <div className={style["wrapper"]}>
        <div className={style["vehicle-details-container"]}>
          <PrimaryHeading
            title={"Vehicle Details"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Vehicle Number</p>
              <InputField
                placeholder={"Vehicle Number"}
                name="vehicleNo"
                handleChange={handleChange}
                value={formData?.vehicleNo}
                regexPattern={/^(?=[A-Za-z]{2})(?=.*[A-Z]).{2,11}$/}
                errorMessage={"Invalid Vehicle Number"}
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Make</p>
              <MultiSelect
                name="brand_id"
                handleChange={(e) =>
                  setFormData({ ...formData, brand_id: e.value })
                }
                items={brandList?.map((ele) => ({
                  label: ele?.brand_name,
                  value: ele?.id,
                }))}
                selectedItems={formData?.brand_id}
                hint={"Select Make"}
                isSearchable={true}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
              />
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Model</p>
              <MultiSelect
                name="model_id"
                handleChange={(e) =>
                  setFormData({ ...formData, model_id: e.value })
                }
                items={modelList?.map((ele) => ({
                  label: ele?.description,
                  value: ele?.id,
                }))}
                selectedItems={formData?.model_id}
                hint={"Select Model"}
                isSearchable={true}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Varient</p>
              <MultiSelect
                name="variant_id"
                handleChange={(e) =>
                  setFormData({ ...formData, variant_id: e.value })
                }
                items={varientList?.map((ele) => ({
                  label: ele?.description,
                  value: ele?.id,
                }))}
                selectedItems={formData?.variant_id}
                hint={"Select Varient"}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
              />
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Fuel Type</p>
              <SelectableButton
                trailingIcon={R.ic_arrow_down}
                containerStyle={style["selectable-button-container-style"]}
                name="fuel_type"
                handleChange={(e) => {
                  setFormData({ ...formData, fuel_type: e.target.value });
                }}
                items={[
                  { label: "Petrol", value: 1 },
                  { label: "Deisel", value: 2 },
                ]}
                value={formData?.fuel_type}
                hint={"Select Fuel Type"}
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Transmission</p>
              <SelectableButton
                trailingIcon={R.ic_arrow_down}
                containerStyle={style["selectable-button-container-style"]}
                name="transmission_id"
                handleChange={(e) => {
                  setFormData({ ...formData, transmission_id: e.target.value });
                }}
                items={[
                  { label: "Manual", value: 1 },
                  { label: "Automatic", value: 2 },
                ]}
                value={formData?.transmission_id}
                hint={"Select Transmission"}
              />
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>KM Driven</p>
              <InputField
                placeholder={"Enter KM Driven"}
                name="driven_kms"
                handleChange={handleChange}
                value={formData?.driven_kms}
                length={6}
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Price</p>
              <InputField
                placeholder={"Enter Price"}
                name="discounted_price"
                handleChange={handleChange}
                value={formData?.discounted_price}
                length={7}
                regexPattern={/^\d+$/}
                errorMessage={"Price should be valid"}
              />
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Discount Percent</p>
              <InputField
                placeholder={"Enter Discount %"}
                name="discountPercent"
                handleChange={handleChange}
                value={formData?.discountPercent}
                length={3}
                regexPattern={/^\d+$/}
                errorMessage={"Discount should be valid"}
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Max Selling Price</p>
              <InputField
                placeholder={"Enter Selling Price"}
                name="actual_price"
                handleChange={handleChange}
                value={
                  Math.ceil(
                    (formData?.discounted_price * formData?.discountPercent) / 100
                  ) + Number(formData?.discounted_price)
                }
                disabled={true}
              />
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Year</p>
              <InputField
                placeholder={"Enter Year"}
                name="make_year"
                handleChange={handleChange}
                value={formData?.make_year}
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Owner</p>
              <InputField
                placeholder={"Enter Owner"}
                name="owner"
                handleChange={handleChange}
                value={formData?.owner}
                regexPattern={/^\d+$/}
                errorMessage={"Owner should be valid"}
                length={1}
              />
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Insurance Type</p>
              <SelectableButton
                trailingIcon={R.ic_arrow_down}
                // containerStyle={style["selectable-button-container-style"]}
                name="insurance_type_id"
                handleChange={(e) => {
                  setFormData({
                    ...formData,
                    insurance_type_id: e.target.value,
                  });
                }}
                items={[
                  { description: "Comprehensive", id: 1 },
                  { description: "Zero Dep", id: 2 },
                  { description: "Own Damage", id: 3 },
                  { description: "Third Party", id: 4 },
                ]?.map((ele) => ({
                  label: ele?.description,
                  value: ele?.id,
                }))}
                value={formData?.insurance_type_id}
                hint={"Select insurance type"}
              />
            </div>

            <div className={style["input-label"]}>
              <p className={style["label"]}>Insurance Valid Upto</p>
              <DateField
                placeholder={"Enter Year"}
                name="insur_validity_dt"
                handleChange={handleChange}
                value={
                  formData?.insur_validity_dt
                    ? moment(formData?.insur_validity_dt).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Flags</p>
              <MultiSelect
                trailingIcon={R.ic_arrow_down}
                containerStyle={style["selectable-button-container-style"]}
                name="flags"
                handleChange={(selectedFlags) => {
                  setFlags(selectedFlags);
                }}
                items={[
                  { label: "TRENDING", value: "TRENDING" },
                  { label: "DISCOUNTED", value: "DISCOUNTED" },
                  { label: "NEWMODEL", value: "NEWMODEL" },
                  { label: "NEWSTOCK", value: "NEWSTOCK" },
                ].filter((item) => !flags.includes(item.value))}
                selectedItems={flags}
                hint={"Select Flags"}
                isMulti={true}
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Status</p>
              <SelectableButton
                trailingIcon={R.ic_arrow_down}
                containerStyle={style["selectable-button-container-style"]}
                name="status"
                handleChange={(e) => {
                  setFormData({ ...formData, status: e.target.value });
                }}
                items={[
                  { label: "Active", value: "Active" },
                  { label: "InActive", value: "InActive" },
                ]}
                value={formData?.status}
                hint={"Select Car Status"}
              />
            </div>
          </div>

          <PrimaryButton
            name={loading ? "Loading..." : "Save"}
            fontSize={"1.5rem"}
            containerStyle={style["primary-button-container-style"]}
            onClick={editCar}
          />
        </div>

        <div className={style["vehicle-images-container"]}>
          <PrimaryHeading
            title={"Upload Vehicle Images"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />

          <div className={style["images-wrapper"]}>
            {/* <ImageCard
              text={"Upload Front Diagonal View - LHS"}
              apiKey={"front_diaog_left"}
              imagesData={imagesData}
              setImagesData={setImagesData}
              previewImage={alreadyImg}
            /> */}
            {imageTypeEnum &&
                imageTypeEnum.length > 0 &&
                imageTypeEnum.map((eachImageType) => {
                  return (
                    <ImageCard
                      text={`Upload ${eachImageType.name}`}
                      apiKey={eachImageType.slug}
                      imagesData={imagesData}
                      setImagesData={setImagesData}
                      previewImage={alreadyImg}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
}
