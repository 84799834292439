import React, { useState } from "react";
import style from "./table.module.scss";
import { testimonialsApi } from "../../../api/testimonials";
import { useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { R } from "../../../constants/resources";

export default function Table({ value, setValues }) {

  const navigate = useNavigate();

  const getStatusLabel = (status) => {
    switch (status) {
      case "1":
        return "Published";
      case "0":
        return "Archived";
      case "2":
        return "Draft";
      default:
        return "Unknown";
    }
  };


  const getDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const dealer_id = localStorage.getItem('dealerId');

  console.log('dealer_id --->', dealer_id);

  const [pagination, setPagination] = useState({
    pageIndex : 0,
    pageSize : 8,
  })
  


  useEffect(()=>{
    const res = async () =>{
      try {
        const res = await testimonialsApi.getTestimonials(dealer_id);
        setValues(res)
        console.log('response-data --->', res);
        
      } catch (error) {
        console.log(error);  
      }
    }
    res();
  }, [dealer_id])

  const onDelete = async (id) => {
   try {
      const res = await testimonialsApi.dropTestimonials(id);
      console.log('your deleted data is -->', res); 
      const updatedRes = await testimonialsApi.getTestimonials(dealer_id);
      setValues(updatedRes);
   } catch (error) {
      console.log(error);    
   }
  };


  const columns = React.useMemo(
    () => [
      {
        accessorKey: "sno",
        header: "S.No",
        cell: (info) => info.row.index + 1,
      },

      // {
      //   accessorKey: "descriptions",
      //   header: "Descriptions",
      //   cell: (info) => {
      //     const description = info.getValue();
      //     return description.length > 20
      //       ? `${description.slice(0, 20)}...`
      //       : description;
      //   },
      // },
      {
        accessorKey : "customer_name",
        header : "Customer Name",
      },
  
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => getStatusLabel(row.original.status),
      },
      {
        accessorKey: "create_at",
        header: "Created Date",
        cell: (info) => getDate(info.getValue()),
      },
      {
        accessorKey: "update_at",
        header: "Updated Date",
        cell: (info) => getDate(info.getValue()),
      },
      {
        accessorKey: "action",
        header: "Actions",
        cell: ({ row }) => <ActionButtons data={row.original} />,
      },
    ],
    []
  );



  const table = useReactTable({
    data : value,
    columns,
    state : {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const ActionButtons = ({ data }) => {

    return (
      <div className={style["action-container"]}>
        <img
          src={R.ic_edit_pen}
          alt="edit"
          onClick={() => onEditClick(data.id)}
          className={style["action-icon"]}
        />
        <img
          src={R.ic_delete}
          alt="delete"
          onClick={() =>onDelete(data.id)}
          className={style["action-icon"]}
        />
      </div>
    );
  };

  const onEditClick = (id) => {
    console.log('edited_id -->', id);
    
    navigate("/create-testinomial", {state : {id : id}});
  };

  return (
    <>
    <div className={`${style["blog-table-container"]} table-responsive`}>
      <table
        className={`${style["table-wrapper"]} table table-bordered table-hover`}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={style["table-header-wrapper"]}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className={style["table-header"]}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={style["table-row"]}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className={style["pagination-action-wrapper"]}>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Back
          </button>
          <p>
            Page {pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </p>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
        </div>

    </div>
    </>
  );
}
