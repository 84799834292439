import React, { useState } from "react";
import style from "./sellerTable.module.scss";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { R } from "../../../../constants/resources";
import { useNavigate } from "react-router-dom";

export default function SellerTable({
  data,
}) {
  const navigate = useNavigate();

  const columns = [
    { accessorKey: "seller_name", header: "Seller Name" },
    { accessorKey: "phone_no", header: "Phone No." },
    { accessorKey: "make", header: "Make" },
    { accessorKey: "model", header: "Model" },
    { accessorKey: "variant", header: "Variant" },
    { accessorKey: "selling_price", header: "Selling Price" },
    { accessorKey: "quote_price", header: "Our Price" },
    { accessorKey: "leadStatus", header: "Status" },
    { accessorKey: "action", header: "Action" },
  ];

  const [pagination, setPagination] = useState({
    pageIndex : 0,
    pageSize : 10,
  })

  const table = useReactTable({
    data,
    columns,
    state : {
      pagination,
    },
    onPaginationChange : setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel : getPaginationRowModel(),
  });

  const Status = ({ value }) => {
    let backgroundColor;
    let color;
    switch (value) {
      case "ACTIVE":
        backgroundColor = "#00B63E";
        color = "#00952A";
        break;
      case "PENDING":
        backgroundColor = "#FCFF5A";
        color = "#E3BF00";
        break;
      case "SOLD":
        backgroundColor = "#D40000";
        color = "#FF0000";
        break;
      case "CONFIRMED":
        backgroundColor = "#FCFF5A";
        color = "#E3BF00";
        break;
      case "Done":
        backgroundColor = "#00B63E";
        color = "#000000";
        break;
      default:
        backgroundColor = "transparent";
        color = "#000";
    }

    const statusStyle = {
      backgroundColor: backgroundColor,
      padding: "5px 10px",
      borderRadius: "20px",
      color: color,
      display: "inline-block",
    };

    return (
      <div className={`${style["status"]} exportable-cell`}>
        <p style={statusStyle}>{value}</p>
      </div>
    );
  };

  const onEditClick = (data) => {
    navigate("/edit-seller-lead", { state: data });
  };

  const Action = (data) => (
    <div className={style["action-container"]}>
      <img
        src={R.ic_edit_pen}
        alt="ic_edit"
        onClick={() => onEditClick(data)}
      />
    </div>
  );

  return (
    <div
      className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
    >
      <table
        className={`${style["table-wrapper"]} table table-border table-hover`}
        cellSpacing={"5px"}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr
                key={headerGroup.id}
                className={style["table-header-wrapper"]}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} className={style["table-header"]}>
                      {header.column.columnDef.header}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} className={style["table-row"]}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      <td key={cell.id}>
                        {cell.column.columnDef.accessorKey === "leadStatus" ? (
                          <Status value={cell.getValue()} />
                        ) : cell.column.columnDef.accessorKey === "action" ? (
                          <Action data={row?.original} />
                        ) : (
                          <>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </>
                        )}
                      </td>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className={style["pagination-action-wrapper"]}>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Back
          </button>
          <p>
            Page {pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </p>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
        </div>


    </div>
  );
}
