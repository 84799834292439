import React, { useEffect, useRef, useState } from "react";
import style from "./imageCard.module.scss";
import { R } from "../../../../constants/resources";
import { API_URL } from "../../../../config";

export default function ImageCard({ text, imagesData, setImagesData, apiKey, previewImage }) {
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(()=>{
    if(previewImage && previewImage.length > 0){
      let finalImg = previewImage.find(x=>x.slug === apiKey)
      if(finalImg && finalImg['img_url']){
        setImagePreview(finalImg['img_url'])
      }
    }
  },[previewImage])

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImagesData([...imagesData , {apikey:apiKey, file: file}]);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setImagePreview(null);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      htmlFor={`file-input-${text}`}
      className={style["image-container"]}
      onClick={handleClick}
    >
      <input
        ref={fileInputRef}
        id={`file-input-${text}`}
        type="file"
        accept="image/*"
        className={style["input-tag"]}
        onChange={handleImageChange}
      />
      {imagePreview ? (
        <div className={style["preview-wrapper"]}>
          <div style={{display:"flex"}}>
          <img
            src={imagePreview}
            className={style["preview-image"]}
            alt={"preview"}
          />
          <span>{text}</span>
          </div>
          <img
            src={R.ic_delete}
            className={style["delete-icon"]}
            alt={"delete"}
            onClick={handleDeleteClick}
          />
        </div>
      ) : (
        <div className={style["wrapper"]}>
          <img src={R.ic_add} className={style["add-icon"]} alt={"add-icon"} />
          <p className={style["text"]}>{text}</p>
        </div>
      )}
    </div>
  );
}
