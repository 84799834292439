import { call } from ".";
import {dealerId} from '../constants/resources'

class SellerApi {
  editSellerLead(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("put", "admin/sellerLead", null, data, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getSellerLead(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `admin/sellerLead?dealerId=${dealerId}`,
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const sellerApi = new SellerApi();
