import { useState, useRef, useEffect } from "react";
import React from "react";
import { Form, Formik } from "formik";
import style from "./create-testimonial.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import { Button } from "@mui/material";
import { testimonialsApi } from "../../../api/testimonials";
import JoditEditor from "jodit-react";
import PrimaryHeading from "../../../components/primary-heading/PrimaryHeading";
import BackButton from "../../../components/buttons/BackButton";
import InputField from "../../../components/input-field/InputField";


const CreateTestimonials = () => {

  const dealer_id = localStorage.getItem('dealerId');

  console.log('dealer_id --->', dealer_id);

  const location = useLocation();

  const {id} = location.state || {};

  const testimonials_id = id;

  console.log('testimonials_id -->', testimonials_id);
  

  const [files, setFiles] = useState({
    image: "",
  });

  const [fileUrl, setFileUrl] = useState({
    image: "",
  });

  const [initialValues, setInitialvalues] = useState({
    description: "",
    customer_name : "",
  });


  const navigate = useNavigate();

  


  useEffect(() =>{
    const getTestimonialsData = async () =>{
      try {
          const res = await testimonialsApi.getTestimonials(dealer_id ,testimonials_id);
          console.log('res --->', res);
                    
          setInitialvalues({
            description : res[0].descriptions || "",
            customer_name : res[0].customer_name || ""
          })

          const customername = res[0].customer_name || "";

          console.log('customer_name is -->', customername);
          

          setFileUrl({
            image : res[0].image || ""
          })

      } catch (error) {
        console.log(error);
      }
    }
    if (testimonials_id) {
      getTestimonialsData();

    }
  }, [dealer_id, testimonials_id])


  const editor = useRef(null);


  const handleSubmit = async (values, {isSubmitting}) => {
    const formData = new FormData();
    formData.append("descriptions", values.description);
    formData.append('image', files.image);
    formData.append('customer_name', values.customer_name);
    formData.append('status', 1);

    if (testimonials_id) {
      formData.append("testimonials_id", testimonials_id)
    }

    try {
      const res = await testimonialsApi.saveTestimonials(dealer_id, formData)
      console.log('response -->', res);
      navigate('/testimonials');
      
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFiles((prevFiles) => ({
      ...prevFiles,
      image: file,
    }));
  
  };



  return (

    <>
    <div className={style["header-wrapper"]}>
         <BackButton
           onClick={() => {
             navigate("/testimonials");
           }}
         />
         <PrimaryHeading
           title={"New Testimonial"}
           fontSize={"1.75rem"}
           fontWeight={600}
         />
       </div>
    
    <div className={style.container}>
      <Formik
        initialValues={initialValues} // Set initialValues here
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={style["form-container"]}>
              <div className={style['wrapper']}>

              <div className={style["input-wrapper"]}>
                  <label> Customer Name</label>
                  <div className={style["logo-header"]}>
                      <input type="text" name="customer_name" className={style['customer_name']} defaultValue={initialValues.customer_name}  onChange={handleChange} />
                  </div>
                </div>


                <div className={style["input-wrapper"]}>
                  <label> Customer Pic</label>
                  <div className={style["logo-header"]}>
                    <input
                      type="file"
                      className={style["file_upload_gcp"]}
                      name="image"
                      accept="image/png, image/jpeg"
                      onChange={(e) =>handleFileChange(e)}
                      onBlur={handleBlur}
                    />
                    {fileUrl.image && (
                      <a href={fileUrl.image} target="_blank" rel="noopener noreferrer">
                        <IoEyeSharp size={30} />
                      </a>
                    )}
                  </div>
                </div>

                <div className={style["about-wrapper"]}>
                  Testimonial 
                  <div className={style["joditor"]}>
                    <JoditEditor
                      ref={editor}
                      name="description"
                      value={values.description}
                      onBlur={(newContent) =>
                        setFieldValue("description", newContent)
                      }
                      config={{
                        placeholder: testimonials_id ? "" : "write content",
                        disablePlugins: "paste",
                        style: {
                          height: "300px",
                          overflowY: "auto", 
                        },
                      }}
                    />
                  </div>
                </div>

              </div>
            </div>

            <div className={style["action-container"]}>
              <div className={style["action-wrapper"]}>
                <Button type="submit" disabled={isSubmitting}>
                  Save & Finish
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
    </>
  );
};

export default CreateTestimonials;
