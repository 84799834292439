import React, { useState, useEffect } from "react";
import style from "./buyerLead.module.scss";
import BuyerEditLeads from "./components/buyer-edit-leads/BuyerEditLeads";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SelectableButton from "../../components/buttons/SelectableButton";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import BuyerTable from "./components/table/BuyerTable";
import { useDispatch, useSelector } from "react-redux";
import { getBuyerList } from "../../store/buyerLead/buyerLeadAction";

export default function BuyerLead() {
  const { buyerLeadList, total } = useSelector((state) => state.buyerLead);

  console.log('buyerLeadList --->', buyerLeadList);
  

  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  // const [data, setData] = useState(buyerLeadList || []);

  useEffect(() => {
    dispatch(getBuyerList());
  }, []);

  // useEffect(() => {
  //   setData(buyerLeadList);
  // }, [buyerLeadList]);

  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const exportToExcel = () => {
    const dataWithoutAction = buyerLeadList.map(({ action, ...rest }) => rest);
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataWithoutAction);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      xlsx.writeFile(workbook, "buyer_data.xlsx");
    });
  };

  // useEffect(() => {
  //   let filterData = data;

  //   if (searchText) {
  //     filterData = data.filter((ele) =>
  //       ele.name.toLowerCase().includes(searchText.toLowerCase())
  //     );
  //   }

  //   if (status) {
  //     filterData = filterData.filter((ele) => ele.status === status);
  //   }

  //   if (!searchText && !status) {
  //     filterData = buyerLeadList;
  //   }

  //   setData(filterData);
  // }, [searchText, status]);


  useEffect(() => {
    dispatch(
      getBuyerList({
        search: searchText || undefined,
        status: status || undefined,
      })
    );
  }, [searchText, status]);

  return (
    <div className={style["container"]}>
      <PrimaryHeading
        title={"Buyer Lead Management"}
        fontSize={"1.75rem"}
        fontWeight={600}
        count={total}
      />

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Buyer Name or Phone"}
            handleChange={handleChange}
          />
          <SelectableButton
            trailingIcon={R.ic_arrow_down}
            containerStyle={style["selectable-button-container-style"]}
            handleChange={(e) => setStatus(e.target.value)}
            items={[
              { label: "Pending", value: "PENDING" },
              { label: "Rejected", value: "REJECTED" },
              { label: "Confirmed", value: "CONFIRMED" },
              { label: "Booked", value: "BOOKED" },
              { label: "Done", value: "Done" },
            ]}
            hint={"All Status"}
            value={status}
          />
        </div>

        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["excel-button-container-style"]}
            leadingIcon={R.ic_excel}
            name={"Export Excel"}
            fontSize={"1rem"}
            onClick={exportToExcel}
          />
        </div>
      </div>
      <BuyerTable
        data={buyerLeadList}
        pagination={pagination}
        setPagination={setPagination}
        total={total}
      />
    </div>
  );
}
