import axiosInstance, { API_URL, DEALER_URL } from "../config";
import {dealerId } from '../constants/resources'
//const accessToken = localStorage.getItem('accessToken');
export const call = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };
  const result = await axiosInstance({
    method,
    url: API_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      // authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const carCall = async (method, url, params = {}, data, token) => {
  const result = await axiosInstance({
    method,
    url: API_URL + url,
    data: data,

    // timeout: 60000,
  });

  return result;
};

export const blogCall = async (method, url, params = {}, data = {}) => {
  // Determine the Content-Type based on data
  const isFormData = data instanceof FormData;
  const contentType = isFormData ? "multipart/form-data" : "application/json";

  try {
    const result = await axiosInstance({
      method,
      url: process.env.REACT_APP_DEALER_URL + 'admin-dealer-auth/' + url,
      params,
      data,
      headers: {
        "Content-Type": contentType,
      },
      responseType: "json",
    });
    return result;
  } catch (error) {
    console.error("Error in blogCall:", error);
    throw error;
  }
};

export const getBlogs = async (method, url, params = {}, data = {}) => {
  const result = await axiosInstance({
    method,
    url: process.env.REACT_APP_DEALER_URL + 'admin-dealer-auth/' + url,
    params,  
    data,    
    headers: {
      'Content-Type': 'application/json'
    },
  });
  return result;
};

export const testimonials = async (method, url, dealer_id, formData) => {
  const result = await axiosInstance({
      method,
      url: `${DEALER_URL}admin-dealer-auth/${url}?dealer_id=${dealer_id}`,
      headers: {
          "Content-Type": "multipart/form-data",
      },
      data: formData,
  });
  return result;
};

export const getTestimonials = async (method, url, { params }) => {
  const result = await axiosInstance({
      method,
      url: `${DEALER_URL}admin-dealer-auth/${url}`,
      headers: {
          "Content-Type": "application/json",
      },
      params
  });
  return result;
};


