import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "./multiSelect.module.scss";

const MultiSelect = ({
  items,
  selectedItems,
  handleChange,
  containerStyle,
  name,
  hint,
  isMulti,
  isSearchable,
  selectClass,
  value,
}) => {
  const options = items?.map((item) => ({
    label: item.label,
    value: item.value,
  }));

  const selectedOptions =
  selectedItems && Array.isArray(selectedItems) && selectedItems.length > 0
      ? selectedItems?.map((item) => ({
          label: item.label,
          value: item.value,
        }))
      : items?.filter((item) => item.value == selectedItems);
  
      const handleChangeMultiSelect = (selectedOptions) => {
    handleChange(selectedOptions);
  };

  return (
    <div className={containerStyle}>
      <Select
        isMulti={isMulti}
        name={name}
        options={options}
        value={selectedOptions}
        onChange={handleChangeMultiSelect}
        classNamePrefix="select"
        placeholder={hint}
        isSearchable={isSearchable}
        className={selectClass}
        isClearable={true}
      />
    </div>
  );
};

MultiSelect.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  containerStyle: PropTypes.string,
  name: PropTypes.string.isRequired,
  trailingIcon: PropTypes.string,
  hint: PropTypes.string,
};

export default MultiSelect;
