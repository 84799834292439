import { testimonials, getTestimonials } from ".";

class TestimonialApi {
    saveTestimonials(dealer_id, formData) {
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await testimonials(
                        "post",
                        `save-testimonials`,
                        dealer_id,
                        formData
                    );
                    console.log('response --->', res);
                    resolve(res);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    dropTestimonials(id){
        const params = {
            id
        }
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await getTestimonials(
                        "put",
                        `drop-testimonials`,
                        { params }
                    );
                    console.log('response --->', res);
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    getTestimonials( dealer_id, testimonials_id) {

        const params = {
            dealer_id,
            testimonials_id
        }

        console.log(params.dealer_id);
        console.log(params.testimonials_id);
        
    
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await getTestimonials(
                        "get",
                        `get-testimonials`,
                        { params }
                    );
                    console.log('response --->', res);
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

}

export const testimonialsApi = new TestimonialApi();
